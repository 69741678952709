/** @jsx jsx */
import { jsx } from 'theme-ui';
import { Fixtures } from '../../components/tickets';

const Match = (props) => {

  return (
    <Fixtures
      {...props}
      variant="match"
    />
  )
}

export default Match